import * as yup from "yup";

const passwordRules = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
//min 5 character, 1 upper case letter, 1 lower case, one numeric digit

export const basicSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  age: yup
    .number()
    .positive("Age must be positive")
    .integer("Must be an integer")
    .required("required"),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a strong password" })
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("Password")], "Password must match")
    .required("required")
});
