import React, { useState } from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";
import styled from "styled-components";

const Foundational = () => {
  //  button state
  const [visitedButtonIndex, setVisitedButtonIndex] = useState(null);
  const [visitedButtonIndex2, setVisitedButtonIndex2] = useState(null);

  const handleButtonClick = (index) => {
    setVisitedButtonIndex(index);
  };
  const handleButtonClick2 = (index2) => {
    setVisitedButtonIndex2(index2);
  };
  return (
    <div>
      <Navbaartesting />
      <div className="flex justify-items-center items-center flex-col">
        <EventHead>Event - {visitedButtonIndex} </EventHead>
        <ButtonDiv className="center">
          {["Foundational", "Preparatory", "Middle", "Secondary"].map(
            (index) => (
              <button
                key={index}
                visited={index === visitedButtonIndex}
                onClick={() => handleButtonClick(index)}
              >
                {index}
              </button>
            )
          )}
        </ButtonDiv>
      </div>
      <div>
        <div className="flex items-center flex-row justify-between pl-[5%] pr-[5%] text-xl font-medium mb-3">
          SELECT MONTH
          <div
            className="flex items-center flex-row  "
            style={{ width: "245px" }}
          >
            <label>Select Year : </label>
            <select className="border-4 pl-[1%] pr-[1%]">
              <option>Select Year</option>
              <option>2022</option>
              <option>2023</option>
              <option>2024</option>
            </select>
          </div>
        </div>
        <ButtonDiv2>
          {[
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ].map((index2) => (
            <button
              key={index2}
              visited={index2 === visitedButtonIndex2}
              onClick={() => handleButtonClick2(index2)}
            >
              {index2}
            </button>
          ))}
        </ButtonDiv2>
      </div>
      <DisplayDiv className="ml-[5%] mr-[5%] mb-4">
        {visitedButtonIndex2}
      </DisplayDiv>
      <Footer />
    </div>
  );
};

export default Foundational;

const EventHead = styled.h1`
  font-size: 30px;
  padding: 1%;
  font-weight: 600;
  color: #2c975b;

  text-transform: uppercase;
`;
const ButtonDiv = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  button {
    padding: 1% 4%;
    border-radius: 25px;
    background-color: ${(props) => (props.visited ? "gray" : "#2c975b")};
    color: white;
    font-weight: 500;
    font-size: 20px;
    &:hover {
      background-color: gray;
    }
    &:clicked {
      background-color: gray;
    }
  }
`;

const ButtonDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  gap: 10px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  border: 1px button {
    border: 1px solid red;
    color: black;
    border-radius: 5px;
    background-color: ${(props) => (props.visited ? "gray" : "White")};
    padding: 1px 20px;
    font-weight: 400;
    font-size: 16px;
  }
`;

const DisplayDiv = styled.div`
  background-color: #eee;
  padding: 1rem;
`;
