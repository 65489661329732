import React, { useState } from "react";
import styled from "styled-components";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";
import CareerImg from "../school_images/career.png";
import CareerMainImg from "../school_images/career_main_image.jfif";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    file: null
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "file" ? files[0] : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your logic to handle form submission here, such as sending data to a server.

    // For demonstration purposes, log the form data to the console.
    console.log(formData);
  };
  return (
    <div>
      <Navbaartesting />
      <MainImageDiv>
        <img src={CareerMainImg} alt="Career_image" className="w-full" />
        <SocialMediaIconsDiv>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.facebook.com/gdgoenkadarbhanga/"
              target="_blank"
            >
              <FaFacebook />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
              target="_blank"
            >
              <IoLogoYoutube />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.instagram.com/gdgoenkadarbhanga"
              target="_blank"
            >
              <FaInstagramSquare />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
            <Link
              to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
              target="_blank"
            >
              <IoLogoLinkedin />
            </Link>
          </div>
        </SocialMediaIconsDiv>
        <MainHeading className=" font-bold pt-10 px-10 uppercase  absolute ">
          Career @ GDGPS
        </MainHeading>
      </MainImageDiv>

      <CareerForm>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label>Phone Number:</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label>Upload PDF/Word File:</label>
            <input
              type="file"
              name="file"
              onChange={handleChange}
              accept=".pdf, .doc, .docx"
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </CareerForm>
      <Footer />
    </div>
  );
};

export default Career;

const CareerForm = styled.div``;

const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
  @media (max-width: 640px) {
    font-size: 30px;
  }
`;

const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  min-height: 300px;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
    min-height: 300px;
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;
