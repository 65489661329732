import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import styled from "styled-components";
import PrincipalImage from "../../school_images/event_4.jpg";

const Leadership = () => {
  return (
    <div>
      <Navbaartesting />
      <LeadershipContainer>
        <h1>meet the faculty</h1>
        <ImageTextDiv>
          <ImageDiv>
            <img src={PrincipalImage} alt="principal" />
          </ImageDiv>
          <TextDiv>
            <h2>About The Principal</h2>
            <p>
              Ms. Anuradha Handa, Founder Principal G D Goenka Public School,
              Sector 48 Gurugram, is a committed educator with an experience
              that spans over 30 years. A passionate teacher at heart, she
              carries a deep understanding of the teaching-learning process and
              its impact on young minds.
            </p>
            <p>
              A Post Graduate in Physics from Delhi University with a
              Professional Degree in Education, Ms. Anuradha Handa’s journey as
              an educationist started with Modern School Vasant Vihar as a post
              graduate teacher She soon went on to head the Physics Department
              there. Having served the premium institute for 16 years and
              exhibiting excellent results, she moved into the role of
              Vice-Principal at Amity International School, Noida and at Apeejay
              School Saket. Later, Ms Handa came back to join Amity
              International School, Sector 46, Gurugram as Principal and
              rendered her priceless contribution as an equally efficient
              administrator there.
            </p>
          </TextDiv>
        </ImageTextDiv>
        <p>
          As the founder Principal of G D Goenka Public School Sector 48
          Gurugram, Ms Handa has spearheaded creatively innovative programs that
          are tailored to the needs of individual students. Under her able
          leadership, GDGPS, Sector 48 Gurugram has been honored for achieving
          Excellence in Implementing Comprehensive & Holistic Learning Approach
          by Edrok India K -12 Awards 2021. Carrying a belief that the purpose
          of education must be to nurture children into happy individuals who
          embody a scientific temper and a problem solving approach to life,
          students under her tutelage grow into outstanding individuals,
          contributing positively to society.
        </p>

        <ImageTextDiv>
          <TextDiv>
            <h2>Headmistress</h2>
            <h2>Foundational & Preparatory</h2>
            <p>
              Ms. Srishti Bajaj, Headmistress Foundational & Preparatory, GD
              Goenka Public School, Gurgaon, firmly believes that Education is
              the source of inspiration that enables our learners to become
              fully and truly human, to fulfill a constructive mission in life
              with composure and confidence. Children are not possessions; they
              are the possessors and the shared treasure of all humanity.
              Education based on respecting children and enabling them to become
              happy will be the driving force for the social change.
            </p>
            <p>
              Ms. Bajaj is a Post Graduate in English with a Professional Degree
              in Education and is an accomplished educationist, an enterprising
              facilitator and an adept team leader.
            </p>
          </TextDiv>
          <ImageDiv>
            <img src={PrincipalImage} alt="jhdsfb" />
          </ImageDiv>
        </ImageTextDiv>
        <p>
          She was The Headmistress, Junior School of GD Goenka Public School,
          Rohini, New Delhi. With a visionary outlook and aspiring demeanor she
          has carved a niche for herself in all the roles assigned to her. She
          has worked as a Pre-Primary teacher with The Springdales School,
          Dhaula Kuan and later with GD Goenka Public School, Vasant Kunj.
        </p>
        <p>
          The formula ‘Learn, Reflect, Empower, Lead’ is the guiding light of
          her life. With thirty two years of rich experience as a proficient
          facilitator, her professional interests centre around the
          incorporation of powerful dynamics in learning practices in classrooms
          and beyond. Her extensive contributions to promote a deeper connect
          between the learners and teachers and her abiding belief in sustaining
          democracy in classrooms and schools reflects in all her endeavors.
        </p>
        <ImageTextDiv>
          <ImageDiv>
            <img src={PrincipalImage} alt="hfsk" />
          </ImageDiv>
          <TextDiv>
            <h2>Headmistress</h2>
            <h2>Middle & Secondary</h2>
            <p>
              Ms Shalini Tandon, Headmistress Middle & Secondary GD Goenka
              Public School, Gurgaon, is a creative and passionate educator who
              carries twenty eight years of experience in the field of
              education. She has been a part of GDGPS 48 family since 2015.She
              believes in being a life-long learner and contributing towards the
              greater cause of education. Her love for children is evident from
              the work that she has done over the years. Right from being a
              dedicated and zealous English Language teacher, Academic
              Coordinator, Wing Incharge, she is also a qualified Art
              Appreciator, Calligrapher and Textile Designer. She is a trained
              ‘Design Think Educator’ and has been fortunate to receive her two
              year training from Design Think experts from Rotterdam University.
              She has also worked as a teacher-counsellor and led several
              projects, including SIAT,on inclusive approach to teaching and
              learning. She has taken training from NIPCID, ICTRC, Sir Ganga Ram
              Hospital and Vimhans for the same. She has been a part of core
              teams of IQF, Joy of Giving projects, Rotary Interact Clubs and
              has been actively contributing and leading Social Outreach
              programmes.
            </p>
          </TextDiv>
        </ImageTextDiv>
        <p>
          She has been part of core teams for designing thematic curriculum,
          writing and Co-authoring text books, editor-in- chief for school
          magazines, leading prefectorial board, ELPP, creating scholastic
          resources for different age groups of students, CAL packages and
          conducting workshops for students and teachers. Her passion for
          writing and theatre made her an active member in theatre workshops and
          annual day planning. Her strong value system, dedication towards her
          work and path breaking approach to teaching and learning was
          recognized and she was honoured with several awards including GTA
          Award by British Council, TGELF Award for Education at Pan Asia Level,
          ISA Coordinator award, Green Campus facilitator award, PTA award for
          dedicated services and outstanding work as an educator.
        </p>
      </LeadershipContainer>
      <Footer />
    </div>
  );
};

export default Leadership;

const LeadershipContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const ImageTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

const ImageDiv = styled.div`
  width: 50%;
  height: auto;
  min-height: 300px;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const TextDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
