import React from "react";

import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Pagenotfound = () => {
  return (
    <div>
      <Navbaartesting />
      Pagenotfound
      <Footer />
    </div>
  );
};

export default Pagenotfound;
