import React from "react";
import styled from "styled-components";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import ProspectImg from "../../school_images/event_4.jpg";

const Prospect = () => {
  return (
    <div>
      <Navbaartesting />
      <MainimageDiv>
        <img src={ProspectImg} alt="Legitimacy" className="w-full" />
      </MainimageDiv>
      <ProspectContainer>
        <h1>Prospect</h1>
        <p>
          Possibility thinking is all about enabling children move from merely
          accepting what they see around them to a stage of wonder at what they
          see around them. Education, in this time of immense change, must
          prepare children find endless possibilities in the uncertainty that
          surrounds them.
        </p>
        <p>
          This enquiry based inquisitive approach is important to develop from a
          young age. Equipping children to think of possibilities allows them to
          explore what lies beyond. They dare to dream. And they carry the grit
          to fulfil their dreams.
        </p>
        <ImageDiv>
          <img src={ProspectImg} alt="Legitimacy" className="w-full" />
          <img src={ProspectImg} alt="Legitimacy" className="w-full" />
        </ImageDiv>
        <p>
          GDGPS Darbhanga prepares its students to explore the world of endless
          possibilities that lay before them. This is achieved through nurturing
          their critical thinking and creative skills. Opportunities are created
          for them to explore; to take decisions; to take responsibility of
          their decisions and most importantly, to always ask, “what if...”
        </p>
        <h2>We Ask Them,</h2>
        <ul>
          <li>What does that remind you of?</li>
          <li>What do you think might happen next?</li>
          <li>What do you/don't you like about this - why</li>
          <li>Is there another way?</li>
          <li>How could we…?</li>
          <li>What would you do if…?</li>
        </ul>
        <p>
          Moreover, we provide them with resources to think. We teach them to be
          fearless; to rise to the challenge; be willing to share and make
          mistakes and be imaginative.
        </p>
      </ProspectContainer>
      <Footer />
    </div>
  );
};

export default Prospect;
const MainimageDiv = styled.div`
  padding: 0 7%;
  max-height: 500px;

  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const ProspectContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
    li {
      text-align: left;
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 300;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
`;

const ImageTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    width: 100%;
  }
  img {
    width: 49%;
    min-width: 350px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const TextDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
