import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Secondary = () => {
  return (
    <div>
      <Navbaartesting />
      Secondary Section
      <Footer />
    </div>
  );
};

export default Secondary;
