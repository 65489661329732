import React from "react";
import Footer from "../components/Footer";

import Navbaartesting from "../components/Navbaartesting";

const Login = () => {
  return (
    <div>
      <Navbaartesting />
      <iframe
        src="https://www.youtube.com/embed/uXWycyeTeCs"
        width={1000}
        height={500}
      ></iframe>
      <Footer />
    </div>
  );
};

export default Login;
