import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";

const Collaboration = () => {
  return (
    <div>
      <Navbaartesting />
      Collaboration
      <Footer />
    </div>
  );
};

export default Collaboration;
