import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CampusTourImg from "../school_images/hostel.jpeg";
import Table from "react-bootstrap/Table";

const WalktheCampus = () => {
  return (
    <div>
      <Navbaartesting />
      <MainImageDiv>
        <img src={CampusTourImg} alt="Career_image" className="w-full" />
        <SocialMediaIconsDiv>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.facebook.com/gdgoenkadarbhanga/"
              target="_blank"
            >
              <FaFacebook />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
              target="_blank"
            >
              <IoLogoYoutube />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.instagram.com/gdgoenkadarbhanga"
              target="_blank"
            >
              <FaInstagramSquare />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
            <Link
              to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
              target="_blank"
            >
              <IoLogoLinkedin />
            </Link>
          </div>
        </SocialMediaIconsDiv>
      </MainImageDiv>
      <WalkTheCampusContainer>
        <h2>Campus Tour</h2>
        <p>
          G D Goenka- Gurgaon has embraced technology and provides for advanced
          infrastructure in a manner characteristic of few city schools in the
          country. The Goenkan Campus has been thoughtfully designed to create a
          dynamic environment which triggers learning and facilitates the
          teaching process. It has been designed to miniscule details and
          addresses the contemporary needs of the Learners.
        </p>
        <h2>Salient Features include: </h2>
        <div>
          <div className="flex space-x-4  justify-start items-center">
            <span className="w-[15px] h-[15px] border bg-red-400 rounded"></span>
            <p style={{ marginBottom: 0 }}>
              Campus spread over 17.00 acres in the heart of Gurgaon City with
              over 3.00 lacs sq. ft of built up area for teaching -learning
              facilitation.
            </p>
          </div>
          <div className="flex space-x-4  justify-start items-center">
            <span className="w-[15px] h-[15px] border bg-blue-400 rounded"></span>
            <p style={{ marginBottom: 0 }}>
              Centrally air conditioned with temperature (heating and cooling)
              control , 100 % power and water back up.
            </p>
          </div>
          <div className="flex space-x-4  justify-start items-center">
            <span className="w-[15px] h-[15px] border bg-green-400 rounded"></span>
            <p style={{ marginBottom: 0 }}>
              IBMS monitoring through Nerve Centre (Control Room), CCTV
              surveillance for monitoring and deterrence, Access control of
              specified areas, Fire Detection and Fighting measures as per
              highest standards.
            </p>
          </div>
          <div className="flex space-x-4  justify-start items-center">
            <span className="w-[15px] h-[15px] border bg-orange-400 rounded"></span>
            <p style={{ marginBottom: 0 }}>
              campus vide broadband e-connectivity.
            </p>
          </div>
        </div>
      </WalkTheCampusContainer>
      <CampusPlan>
        <h2 className="uppercase mb-4 mt-4">Campus Plan</h2>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Entrance</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">Visitors Main Entrance, Bus Parking</p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Administration</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">
                  {" "}
                  Reception Hall, Administration Wing, Principal’s Chamber,
                  Conference Hall
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Academic Blocks</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">
                  Classrooms, School Infirmary, Counseling Area, Library,
                  Computer And Language Labs
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Sports Facilities</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">
                  Indoor All Weather Swimming Pools, Squash Courts, Indoor
                  Games, Yoga And Aerobics Studios, Athletic Track, Football
                  Field, Cricket Ground, Lawn Tennis Courts, Basketball Courts,
                  Badminton Courts, Horse Riding, Skating Rink, Youngsters Play
                  Area And Swings
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Dining</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">Cafteria And Dining Hall</p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Performances</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">
                  750 Seat Auditorium, Indoor Multipurpose Hall, Amphitheatre
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ fontWeight: 600 }}> Activity Block</p>
              </td>
              <td>
                <p className="pl-4">:</p>
              </td>
              <td>
                <p className="pl-4">
                  Indian And Western Dance Studios, Indian And Western Music
                  Studios, Visual Arts Rooms, Performing Arts (theatre) Studios,
                  Senior Labs
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </CampusPlan>
      <Footer />
    </div>
  );
};

export default WalktheCampus;

const CampusPlan = styled.div`
  background-color: #eee;
  padding: 0 7%;

  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 40px;
    color: #2c975b;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
`;

const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;

const WalkTheCampusContainer = styled.div`
  padding: 1% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;
