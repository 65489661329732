import React from "react";
import Footer from "../../components/Footer";
import Navbaartesting from "../../components/Navbaartesting";
import ProficiencyImg from "../../school_images/careery.jpg";
import styled from "styled-components";

const Proficiency = () => {
  return (
    <>
      <Navbaartesting />
      <MainimageDiv>
        <img src={ProficiencyImg} alt="Legitimacy" className="w-full" />
      </MainimageDiv>
      <ProficiencyContainer>
        <h1>Proficiency</h1>
        <p>
          Productivity, as understood commonly, is the ratio between the output
          volume and the volume of inputs. However, individuals can have their
          own understanding of productivity, and in that sense, define their own
          productivity levels.
        </p>
        <p>
          Education’s productivity is extremely critical, for an individual’s
          potential is realized through education. Achieving personal success in
          an appropriate length of time and collectively contributing to society
          is what defines an outstanding individual.
        </p>
        <p>
          The key question thus centres around, how to develop an aspiration to
          be highly productive?
        </p>

        <ImageTextDiv>
          <TextDiv>
            <p>
              This desire to excel is inculcated during the crucial 14 years
              that a child spends at school. The ability to be focused, to
              harness the body and mind, to drop the non essentials and to keep
              things simple; these are some of the areas children ought to be
              thinking about while in school.
            </p>
            <p>
              GDGPS Sector 48 shapes this thinking to excel among its students.
              It first starts with developing an understanding of the concept of
              time management. Time cannot really be managed, for we all have
              only so much of it. We have no control over it. What we do have
              control over is how we expend our energy in the time we are
              allotted.
            </p>
            <p>
              Having a clear academic plan; sharing the same with the students;
              preparing them for it in advance; inculcating a habit of planning
              and following the plan through, are some of the means adopted, to
              enable students maximise their productivity.
            </p>
          </TextDiv>
          <ImageDiv>
            <img src={ProficiencyImg} alt="image" className="w-[49%] " />
          </ImageDiv>
        </ImageTextDiv>
        <ImageTextDiv2>
          <ImageDiv>
            <img src={ProficiencyImg} alt="image" className="w-[49%] " />
          </ImageDiv>
          <TextDiv>
            <p>
              Further, centring on the four essential elements of productivity,
              namely Purpose, Language, Focus and Physiology, Goenkans carry a
              natural flair to excel.
            </p>
            <p>
              We teach them to be clear about the purpose of what they are
              doing;
            </p>
            <p>
              We nurture them to be mindful of the language they use, whether
              while talking to others or while listening to their inner chatter;
            </p>
            <p>We enable them to remain focused on their objective;</p>
            <p>And we inculcate physical well being.</p>
          </TextDiv>
        </ImageTextDiv2>
      </ProficiencyContainer>
      <Footer />
    </>
  );
};

export default Proficiency;

const MainimageDiv = styled.div`
  padding: 0 7%;
  max-height: 500px;

  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const ProficiencyContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const ImageTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

const ImageTextDiv2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  height: auto;
  min-height: 300px;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const TextDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
