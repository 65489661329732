import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import Lagacee from "../../school_images/connectivity_image_for_School.jpg";
import Img1 from "../../school_images/hostel.jpeg";
import Img2 from "../../school_images/event3.jpg";
import Slide from "../../school_images/slide-4.jpg";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Legacy = () => {
  return (
    <div>
      <Navbaartesting />
      <MainImageDiv>
        <img src={Slide} alt="Career_image" className="w-full" />
        <SocialMediaIconsDiv>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.facebook.com/gdgoenkadarbhanga/"
              target="_blank"
            >
              <FaFacebook />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
              target="_blank"
            >
              <IoLogoYoutube />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.instagram.com/gdgoenkadarbhanga"
              target="_blank"
            >
              <FaInstagramSquare />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
            <Link
              to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
              target="_blank"
            >
              <IoLogoLinkedin />
            </Link>
          </div>
        </SocialMediaIconsDiv>
        <MainHeading className=" font-bold pt-10 px-10 uppercase  absolute ">
          legacy
        </MainHeading>
      </MainImageDiv>

      <div className="flex justify-start items-start flex-col p-[5%] align-left text-justify">
        <h1 className="font-bold text-3xl pb-8">START OF A DREAM</h1>
        <p className="pb-4">
          It started with a dream... a glorious vision to create an ambiance
          conducive to creative learning. A dream which is an innovative route
          towards providing Global Education with its feet firmly grounded in
          the Indian soil. A humble endeavour to touch the grandest heights of
          excellence in Education. A school with a difference!
        </p>
        <p>
          A school that became a trendsetter in radical and progressive
          education, the quality of facilities and its gamut of opportunities.
          Established in 2022, G D Goenka Public School, Darbhanga was the
          brainchild of G D Goenka Group Chairman Shri Anjani Kumar Goenka. He
          aimed to immortalize the name of his mother Shrimati Gayatri Devi
          Goenka and hoped to provide the students with a sound education
          unheard and unexplored of before. His innovative mission reached the
          pinnacle of success due to the detailed execution of plans by Smt Renu
          Goenka. Her finesse and sensitivity worked in unison with Shri A.K.
          Goenka’s visionary enterprise, to give the school its present
          standard.
        </p>
      </div>
      <div
        className="flex justify-start items-start flex-row p-[5%] align-left text-justify"
        id="history"
      >
        <div className="flex justify-start items-start flex-col w-[50%]  align-left text-justify">
          <img src={Img2} alt="Img2" />
        </div>
        <div className="flex justify-start items-start flex-col w-[50%] align-left text-justify">
          <h1 className="font-bold text-xl pb-6 pl-2">
            A LEGACY OF EXCELLENCE
          </h1>
          <p className="pl-2 pb-4">
            Today, under the aegis of G D Goenka Group, the G D Goenka Public
            Schools, are regarded as centres of educational excellence globally.
            The school philosophy, based on a set of strongly held beliefs, is
            put into action everyday by every member of the institution. It is a
            philosophy of treating each child in a sensitive manner and
            providing appropriate developmental programs suiting the individual
            needs, so as to encourage not just learning but also the love for
            learning. It is also based on providing an environment where
            classrooms are arranged in a manner that it offers challenging play
            and learning choices inter- woven with the pedagogy, directed at a
            range of varied development levels. Activity areas are designed
            where children are allowed to explore, experience and most
            importantly, succeed.
          </p>
          <p className="pl-2">
            The illustrious legacy of the G D Goenka Group was taken forward by
            G D Goenka Public School, Darbhanga. GDGPS Darbhanga was established
            in the year 2022 with classes Nursery to grade VII in its first
            year.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Legacy;

const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
`;

const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;
