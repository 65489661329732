import React from "react";
import Footer from "../components/Footer";

import Navbaartesting from "../components/Navbaartesting";

const Academic = () => {
  return (
    <div>
      <Navbaartesting />
      Academic
      <Footer />
    </div>
  );
};

export default Academic;
