import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import Chairmann from "../../school_images/school_logo.jpeg";
import Vision from "../../school_images/school_logo.jpeg";

const Chairman = () => {
  return (
    <div>
      <Navbaartesting />
      <h1 className="text-center mt-5">Chairman's Message</h1>
      <div>
        <div>
          <img src={Chairmann} alt="chairman" />
        </div>
        <div>
          <h1>THE VISIONARY </h1>
          <p>
            As India started to establish its own distinct identity in the
            world; as it started to open its doors to the world economy, as it
            started to position itself globally, it became important for the
            education system of the country to undergo a transformation. Our
            future generations had to be fully equipped to win on the global
            stage. For this it was imperative that their schooling be of
            international standards while keeping the Indian Value System
            intact. It was a daunting ambition.
          </p>
        </div>
        <div>
          <p>
            In establishing G D Goenka Public School, Sector 48, Gurugram, the
            management had the vision of an ideal educational institute that
            would raise happy children with a social, moral and emotional
            fortitude. We were aware of the impact quality education has on
            society. Hence, our endeavour, from the very beginning, has been to
            create a positive enabling environment, a place, where children and
            their facilitators both can be happy. We understand that a happy
            individual is a constructive individual, who always believes in
            leaving a positive impact on society.
          </p>
          <p>
            Aldous Huxley, famous English philosopher and writer who pioneered
            the study of Techniques to Develop Human Potential, was once asked
            about the most effective technique to transform a person’s life. His
            answer is fascinating. He said, “It’s a little embarrassing that
            after years and years of research, my best answer is – just be a
            little kinder.” More than a century later, his answer could not be
            more relevant today than in the previous century! That technology is
            going to be the game changer is inevitable. But how do we ensure
            that it does not become a destructive force? By aligning technology
            with humanity; And this is where key human values come into play. To
            show kindness, to feel empathy, to have gratitude, to care for
            others; is what we need to cultivate in our children today.
          </p>
          <p>
            My constant thought revolves around how Goenkan students are
            perceived by people in the world outside. How would I want them to
            be known?
          </p>
          <p>
            As founders, we wanted to build an institute that could have
            positive results for students, for parents and for teachers. We
            wanted to provide resources that would enhance humanness in
            children. And I feel a deep sense of satisfaction when I see this
            humanness being kept alive here at GDGPS sector 48 Gurgaon. While we
            are absolutely committed to preparing our children adequately for
            the technologically driven future, we are also ensuring that they
            develop creativity and wisdom that adapts to the ever changing
            reality.
          </p>
          <p>
            As children grow to have faith in their own abilities, the will pave
            many new frontiers. The only problem is, they grow very fast ! With
            each passing year, they will grow a little more independent, a
            little more distant, a little more secretive and a little more
            buoyant! And no matter how much you hold on to them, they will
            eventually find their own path in life. As parents, it is our
            primary duty to equip them with abilities that are required to lead
            a happy life of value. We must remember that their future is in our
            hands. That goenkans are being received so well, as they step out of
            school to pursue higher education; and, to see them being perceived
            as credible beings, is a highly assuring. It is proof that we did
            create a bright future for them.
          </p>
          <p>
            Fortunate are those who are able to make their passion their
            profession. When we love what we do, the thin line between work and
            passion melts. I consider myself fortunate to have been provided
            with an enabling and empowering environment in my growing up years
            because of which I could go on to pursue what I love. However, I
            feel more fortunate to see that the vision that I, along with other
            members of the management, started with, bloom so beautifully in the
            form of GDGPS Sector 48.
          </p>
        </div>
        <div>
          <div>
            <h1>OUR VISION</h1>
            <p>
              Education is the apprenticeship of life. Our vision is to provide
              value based education with new innovations and ideas so that our
              pupils grow into aesthetically rich, intellectually aware and
              integrated young people, capable of fulfilling their dreams and
              aspirations.
            </p>
            <p>
              Our vision is to strengthen the basic foundation and thereafter
              allow the latent talent to fully develop. Creative energies need a
              caring and nurturing environment and this is what we endeavour to
              provide.
            </p>
          </div>
          <img src={Vision} alt="Vision" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Chairman;
