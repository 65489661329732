import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Middle = () => {
  return (
    <div>
      <Navbaartesting />
      Middle Section
      <Footer />
    </div>
  );
};

export default Middle;
