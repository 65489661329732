import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import styled from "styled-components";
import SynergyImg from "../../school_images/slide-0.png";

const Synergy = () => {
  return (
    <div>
      <Navbaartesting />
      <MainimageDiv>
        <img src={SynergyImg} alt="Legitimacy" className="w-full" />
      </MainimageDiv>
      <SynergyContainer>
        <h1>Synergy</h1>
        <p>
          In an increasingly digital world that our children are growing up in,
          technology has taken precedence over everything else. Separateness
          seems to be the norm as human interactions are being replaced by
          electronic interactions. In such a scenario, maintaining authentic
          human interactions becomes paramount.
        </p>
        <p>
          Connectivity happens at various levels in a school environment. First
          and foremost are the connections forged between a teacher and a
          student. GDGPS Sector 48 puts all its energy behind consolidating this
          connection, for we believe that a teacher is the most important and
          crucial school ‘environment’.
        </p>
        <p>
          When a teacher creates a non-threatening learning environment, every
          student experiences a sense of freedom to express, without being
          judged. They start to connect with each other. They also start to
          understand the connections they share with people in the outside world
          and learn to recognize and respect people of different cultures,
          ethnicity, gender and colour. Learning, growing and succeeding
          together becomes the school culture. 14 years spent in such a culture
          surely creates young adults who believe in forming connections with
          others and take everyone along in their life journey.
        </p>
        <p>
          Needless to say, authenticity in all that we do leads to connectivity.
        </p>
        <ImageDiv>
          <img src={SynergyImg} alt="image" className="w-[49%] " />
          <img src={SynergyImg} alt="image" className="w-[49%] " />
          <img src={SynergyImg} alt="image" className="w-[49%] " />
        </ImageDiv>
      </SynergyContainer>
      <Footer />
    </div>
  );
};

export default Synergy;

const MainimageDiv = styled.div`
  padding: 0 7%;
  max-height: 500px;

  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const SynergyContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    width: 100%;
  }
  img {
    width: 33%;
    min-width: 350px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const TextDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
