import React from "react";
import Footer from "../components/Footer";
import Navbaartesting from "../components/Navbaartesting";

const Lifeatgdgps = () => {
  return (
    <div>
      <Navbaartesting />
      LifeAtGDGPSDarbhanga
      <Footer />
    </div>
  );
};

export default Lifeatgdgps;
