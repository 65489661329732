import React, { useState } from "react";
import styled from "styled-components";
import Navbaartesting from "../components/Navbaartesting";
import { Link, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import { MdAppRegistration } from "react-icons/md";
import { MdBookOnline } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { MdPolicy } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FaSchool } from "react-icons/fa6";
import { FaBookAtlas } from "react-icons/fa6";

const AdmissionEnquary = () => {
  const [showContent, setShowContent] = useState(false);

  const handleNavigationClick = () => {
    setShowContent(true);
  };

  return (
    <>
      <Navbaartesting />
      <AdmissionContainer>
        <AdmissionDetailSection>
          {showContent ? (
            <Outlet />
          ) : (
            <Cont1>
              <H1>ADMISSION</H1>
              <P>
                GDGPS DARBHANGA, established in the year 2013, follows the CBSE
                curriculum. Nursery to Class XII is housed within the same
                campus, with separate areas dedicated for Foundational,
                Preparatory, Middle and Secondary years.
              </P>
              <H2>Nursery Admissions </H2>
              <P>
                Nursery is the entry level class in the preparatory level and is
                sure to witness great enthusiasm during Nursery Admissions in
                DARBHANGA. We know you want the best for your child, and so, we
                go that extra mile in providing the best learning environment
                for your child. Right from giving individual attention, to
                creating conditions conducive to collaborative learning, our
                pedagogical approach is entirely learner centered.
              </P>
              <P>
                We are the ideal choice if you are seeking nursery admission for
                2024-25 in DARBHANGA and are interested in a comprehensive
                educational experience for your children. Nursery Admissions in
                DARBHANGA at GDGPS DARBHANGA, the process is designed in a
                manner that is fair and transparent; aligning parents with the
                vision of the school.
              </P>
              <H2>Admission in Class 1 to Class 9</H2>
              <P>
                The school maintains an optimum student teacher ratio of 1:12.
                The first phase of the school admission in DARBHANGA for an
                academic session opens in the month of August / September of the
                previous session. The second phase is from the month of January.
                Seats fallen vacant due to transfers, in a particular class /
                section, are offered to students seeking admittance during the
                course of the session. Our primary criteria for accepting an
                application for school admission in DARBHANGA is based on the
                child meeting the age criteria. Priority is given in the
                following order:
              </P>
              <P>
                In contrast to other schools, our process for school admission
                in DARBHANGA is transparent and straightforward. We are
                committed to offering more opportunities to talented students
                who are eager to join our school and meet our admission
                criteria. There are no concealed steps in our selection process.
              </P>
              <P>
                Fostering a child’s character is the very heart of education.
                Hence, which school a child attends, becomes the most important
                decision that parents make. At GDGPS DARBHANGA we keep the
                process of Nursery admissions and the admission in other classes
                such that you can have a clear and authentic view of our school.
                All valid applications are taken through Session by the School
                Principal during the process of school admission in DARBHANGA.
              </P>
              <P>
                The objective of the session is to give parents a deeper
                understanding of the true purpose of school education. Schooling
                is not just about raising children into upright young adults. It
                is about nurturing Whole Beings who are confident, independent,
                creative and carry a positive problem solving approach. How do
                we ensure that this purpose of education is achieved; what is
                the thought behind the creative and innovative school programs
                that make the curriculum implementation process effective; how
                every area of the ergonomically designed campus enhances a
                child’s learning process; how the specific requirements of each
                age group of children are met and how parents and school
                collaborate to make the longest years of a child’s life most
                fruitful and fulfilling; is addressed in the Session with the
                Principal
              </P>
              <P>
                An understanding of the objective of school education is then
                sought from the parent. To ensure that a child’s school journey
                is a happy one, a perfect alignment between school and parent is
                essential. A parent’s ability to reveal how, educating their
                ward at GDGPS, DARBHANGA, is going to benefit their child and
                the community at large; is a crucial factor in accepting school
                admission in DARBHANGA.
              </P>
              <H2>THE PROCESS OF ADMISSION AT GDGPS DARBHANGA COMPRISES OF:</H2>
              <P>
                Registering online along with submission of requisite documents.
              </P>
              <P>
                All valid registrations receiving intimation from school
                regarding virtual meet with School Principal.
              </P>
              <P>
                Session by School Principal to share the vision, mission,
                philosophy, way of working and the milestones achieved by a
                child during the 14 years at school, on a pre defined date,
                allotted in consultation with the parent.
              </P>
              <P>
                On grant of admission, completion of admission formalities that
                include submission of school fee, filling the Admission Form and
                verification of necessary documents.
              </P>
              <P>
                Our admission process maintains transparency across all classes,
                be it nursery admissions, Class 11, or any other grade. All you
                need to do is closely monitor the admission dates on our
                website, apply in a timely manner, and ensure that you meet all
                the required criteria for eligibility.
              </P>
            </Cont1>
          )}
        </AdmissionDetailSection>

        <AdmissionNavigationSection>
          <Div1>
            <IconDiv>
              <MdAppRegistration
                className="text-4xl "
                style={{ color: "purple" }}
              />
            </IconDiv>
            <Link to="/admission/registration" onClick={handleNavigationClick}>
              CLICK FOR REGISTRATION
            </Link>
          </Div1>
          <Div2>
            <IconDiv>
              <MdBookOnline className="text-4xl " style={{ color: "red" }} />
            </IconDiv>
            <Link to="/admission/online-modal" onClick={handleNavigationClick}>
              THE ONLINE MODAL
            </Link>
          </Div2>
          <Div3>
            <IconDiv>
              <FaQuestionCircle
                className="text-4xl "
                style={{ color: "skyblue" }}
              />
            </IconDiv>
            <Link to="/admission/faq" onClick={handleNavigationClick}>
              FREQ ASKED QUESTIONS
            </Link>
          </Div3>
          <Div4>
            <IconDiv>
              <MdPolicy className="text-4xl " style={{ color: "gray" }} />
            </IconDiv>
            <Link
              to="/admission/admission-process"
              onClick={handleNavigationClick}
            >
              ADMN PROCESS & POLICIES
            </Link>
          </Div4>
          <Div5>
            <IconDiv>
              <IoDocuments className="text-4xl " style={{ color: "green" }} />
            </IconDiv>
            <Link
              to="/admission/documents-required"
              onClick={handleNavigationClick}
            >
              DOCUMENT REQUIRED
            </Link>
          </Div5>
          <Div6>
            <IconDiv>
              <FaPersonBreastfeeding
                className="text-4xl "
                style={{ color: "orange" }}
              />
            </IconDiv>
            <Link to="/admission/fee-structure" onClick={handleNavigationClick}>
              FEE STRUCTURE
            </Link>
          </Div6>

          <Div7>
            <IconDiv>
              <HiOutlineSpeakerphone
                className="text-4xl "
                style={{ color: "purple" }}
              />
            </IconDiv>
            <Link
              to="/admission/success-stories"
              onClick={handleNavigationClick}
            >
              GOENKAN SUCCESS STORIES
            </Link>
          </Div7>
          <Div8>
            <IconDiv>
              <FaSchool className="text-4xl " style={{ color: "yellow" }} />
            </IconDiv>
            <Link to="/admission/campus-tour" onClick={handleNavigationClick}>
              CAMPUS TOUR
            </Link>
          </Div8>
          <Div9>
            <IconDiv>
              <FaBookAtlas className="text-4xl " style={{ color: "brown" }} />
            </IconDiv>
            <Link
              to="/admission/school-prospectus"
              onClick={handleNavigationClick}
            >
              SCHOOL PROSPECTUS
            </Link>
          </Div9>
        </AdmissionNavigationSection>
      </AdmissionContainer>
      <Footer />
    </>
  );
};

export default AdmissionEnquary;

const H1 = styled.h1`
  font-size: 35px;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;
const H2 = styled.h2`
  font-size: 28px;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const P = styled.p`
  text-align: left;
  font-size: 18px;
  margin-bottom: 1.5rem;
`;

const Cont1 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const AdmissionContainer = styled.section`
  width: 100%;
  height: auto;
  padding: 5% 7%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const AdmissionDetailSection = styled.section`
  width: 70%;
  @media (max-width: 900px) {
    width: 90%;
  }
`;
const AdmissionNavigationSection = styled.section`
  width: 30%;
  border: 1px solid pink;

  @media (max-width: 900px) {
    width: 90%;
  }
`;

const IconDiv = styled.div`
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Div2 = styled.div`
  width: 100%;

  background-color: red;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div1 = styled.div`
  width: 100%;

  background-color: purple;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div3 = styled.div`
  width: 100%;

  background-color: lightblue;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div4 = styled.div`
  width: 100%;

  background-color: grey;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div5 = styled.div`
  width: 100%;

  background-color: green;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div6 = styled.div`
  width: 100%;

  background-color: orange;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div7 = styled.div`
  width: 100%;

  background-color: purple;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div8 = styled.div`
  width: 100%;

  background-color: yellow;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div9 = styled.div`
  width: 100%;

  background-color: brown;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Div10 = styled.div`
  background-color: yellow;
  height: 70px;
  &:hover {
    background-color: #c79006;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 30px;
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
    padding-left: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
