import React from "react";

import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Contactus = () => {
  return (
    <div>
      <Navbaartesting />
      Contact Us Page
      <Footer />
    </div>
  );
};

export default Contactus;
