import React from "react";
import Footer from "../../components/Footer";
import Navbaartesting from "../../components/Navbaartesting";

const Newslatter = () => {
  return (
    <div>
      <Navbaartesting />
      News Letters
      <Footer />
    </div>
  );
};

export default Newslatter;
