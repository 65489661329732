import React from "react";
import styled, { keyframes } from "styled-components";
import Marquee from "react-fast-marquee";
import Marque from "./Marque";
import "../styleCSS/tenetsStyle.css";
import { Link } from "react-router-dom";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { FaImages } from "react-icons/fa";
import Event1 from "../school_images/event_1_.jpg";
import Event2 from "../school_images/tenet_2.jpg";
import Event3 from "../school_images/event3.jpg";
import Event4 from "../school_images/event_4.jpg";
import { IoNewspaperSharp } from "react-icons/io5";

const NoticeEvents = () => {
  return (
    <NoticeEventDiv>
      <NoticeBoard>
        <h1> Notice Board </h1>
        <Marque />
      </NoticeBoard>
      <EventContainer>
        <EventH>EVENTS</EventH>
        <EventsDiv>
          <Ev1>
            <Link to="/foundational">
              <img src={Event1} alt="event1" />
              <p>Foundational</p>
              <span>Nur to I</span>
            </Link>
          </Ev1>

          <Ev2>
            <Link to="/foundational">
              <img src={Event2} alt="event2" />
              <p>Preparatory</p>
              <span>II to V</span>
            </Link>
          </Ev2>
          <Ev3>
            <Link to="/foundational">
              <img src={Event3} alt="event3" />
              <p>Middle</p>
              <span>VI to VIII</span>
            </Link>
          </Ev3>
          <Ev4>
            <Link to="/foundational">
              <img src={Event4} alt="event4" />
              <p>Secondary</p>
              <span>IX to X</span>
            </Link>
          </Ev4>
        </EventsDiv>

        <GalleryDiv>
          <div className="gallery gallery1">
            <FaImages className="galleryIcon" />
            <Link to="/img-gallery">Image Gallery</Link>
          </div>

          <div className="gallery gallery2">
            <MdOutlineVideoLibrary className="galleryIcon" />
            <Link to="/vid-gallery">Video Gallery</Link>
          </div>
        </GalleryDiv>
        <NewsContainer>
          <IoNewspaperSharp className="newsIcon" />
          <Link to="/newsletters">News Letters</Link>{" "}
        </NewsContainer>
      </EventContainer>
    </NoticeEventDiv>
  );
};

export default NoticeEvents;

const EventH = styled.span`
  font-size: 35px;
  color: #2c975b;
  font-weight: 600;
`;

const EventsDiv = styled.div`
  height: 500px;
  width: calc(100%-300px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
`;

const Ev1 = styled.div`
  cursor: pointer;
  position: relative;
  width: 49%;
  height: 250px;
  margin-bottom: 1rem;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    height: 200px;
  }
  img {
    height: 250px;
    width: 100%;
    object-position: center;
    object-fit: fit;
    @media (max-width: 500px) {
      height: 200px;
    }
  }
  p {
    position: absolute;
    top: 75%;
    color: white;
    font-size: 20px;
    right: 10%;
  }
  span {
    position: absolute;
    top: 85%;
    right: 10%;
    color: white;
  }
`;
const Ev2 = styled.div`
  position: relative;
  width: 49%;
  height: auto;
  margin-bottom: 1rem;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    height: 200px;
  }
  img {
    height: 250px;
    width: 100%;
    object-position: center;
    object-fit: fit;
    font-wight: 600;
    @media (max-width: 500px) {
      height: 200px;
    }
  }
  p {
    position: absolute;
    top: 75%;
    right: 10%;
    color: white;
    font-size: 20px;
  }
  span {
    position: absolute;
    top: 85%;
    right: 10%;
    color: white;
  }
`;
const Ev3 = styled.div`
  position: relative;
  width: 49%;
  height: auto;
  margin-bottom: 1rem;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    height: 200px;
  }

  img {
    height: 250px;
    width: 100%;
    object-position: center;
    object-fit: fit;
    @media (max-width: 500px) {
      height: 200px;
    }
  }
  p {
    position: absolute;
    top: 75%;
    right: 10%;
    font-weight: 600;
    color: black;
    font-size: 20px;
  }
  span {
    position: absolute;
    top: 85%;
    right: 10%;
    color: black;
  }
`;
const Ev4 = styled.div`
  position: relative;
  width: 49%;
  height: auto;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    height: 200px;
  }
  img {
    height: 250px;
    width: 100%;
    object-position: center;
    object-fit: fit;
    @media (max-width: 500px) {
      height: 200px;
    }
  }
  p {
    position: absolute;
    top: 75%;
    color: white;
    font-size: 20px;
    right: 10%;
  }
  span {
    position: absolute;
    top: 85%;
    right: 10%;
    color: white;
  }
`;

const NoticeEventDiv = styled.div`
  margin: 5% 7%;
  height: auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NoticeBoard = styled.div`
  width: 29%;
  color: #fff;

  background-color: #2c975b;

  @media (max-width: 768px) {
    width: 100%;
  }

  h1 {
    font-size: 30px;
    margin: 0 0 15px 0;
  }
`;
const Events = styled.div`
  margin-top: 35px;
  width: calc(100%-300px);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const EventContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;

  padding: 0 0 2rem 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    height: auto;
  }
`;

const GalleryDiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4rem;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 20px;
  }
`;

const NewsContainer = styled.div`
  padding: 5% 3%;
  height: 100px;
  width: 100%;
  margin-top: 3rem;

  background-color: #2c975b;
  display: flex;
  position: relative;

  a {
    position: absolute;
    top: 5%;
    right: 30%;
    font-size: 60px;
    text-decoration: none;
    color: White;
    &:hover {
      color: red;
    }
    @media (max-width: 768px) {
      font-size: 30px;
      right: 20%;
      top: 10%;
    }
  }
`;
