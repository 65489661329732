import React from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { basicSchema } from "../../schemas";
import styled from "styled-components";

const onSubmit = () => {
  console.log("Submitted");
};

const Registration = () => {
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      selectedOption: ""
    },
    validationSchema: basicSchema,
    onSubmit
  });

  // Dropdown options
  const academicYear = [
    { value: "2023-24", label: "2023-24" },
    { value: "2024-25", label: "2024-25" }
  ];

  const classOptions = [
    { value: "nur", label: "NUR" },
    { value: "kg", label: "KG" },
    { value: "i", label: "I" },
    { value: "ii", label: "II" },
    { value: "iii", label: "III" },
    { value: "iv", label: "IV" },
    { value: "v", label: "V" },
    { value: "vi", label: "VI" },
    { value: "vii", label: "VII" },
    { value: "viii", label: "VIII" },
    { value: "ix", label: "IX" }
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ];

  console.log("errors", errors); // to see the form errors

  return (
    <RegistrationFormContainer>
      <HeadingP>Admission Registration Form</HeadingP>
      <Formik
        initialValues={{ email: "email" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form on Submit={handleSubmit} autoComplete="off">
            {/* Dropdown input field */}
            <Div1>
              <label htmlFor="Select-academic-year">
                Select-Academic-Year:
              </label>
              <Field
                as="select"
                id="Select-academic-year"
                name="Select-academic-year"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              >
                <option value="" label="Select Academic Year" />
                {academicYear.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>{" "}
            </Div1>
            <Div2>
              {/* Displaying form errors, if any */}
              <ErrorMessage name="selectedOptionClass" component="div" />
              {/* Dropdown input field */}
              <label htmlFor="selectedOptionClass">Select Class:</label>
              <Field
                as="select"
                id="selectedOptionClass"
                name="selectedOptionClass"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              >
                <option value="" label="Select an option" />
                {classOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>{" "}
            </Div2>
            <Div9>
              <label htmlFor="studentName">Student's Name:</label>
              <Field
                type="text"
                name="studentName"
                placeholder="Enter Student's Name"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              />
              <ErrorMessage
                name="studentName"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </Div9>
            <Div3>
              {/* Displaying form errors, if any */}
              <ErrorMessage name="selectedOption" component="div" />
              {/* Dropdown input field */}
              <label htmlFor="selectedOption">Select Your Gender:</label>
              <Field
                as="select"
                id="selectedOption"
                name="selectedOption"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              >
                <option value="" label="Select an option" />
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </Div3>
            {/* Displaying form errors, if any */}
            <Div4>
              <label htmlFor="fatherName">Father's Name:</label>
              <Field
                type="text"
                name="fatherName"
                placeholder="Enter Father's Name"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              />
              <ErrorMessage
                name="fatherName"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </Div4>
            <Div5>
              <label htmlFor="select-dob">Date of Birth:</label>
              <Field
                type="date"
                name="select-dob"
                id="date"
                placeholder="Date of  Birth"
                label="Date of Birth"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              />
              <ErrorMessage
                name="select-dob"
                component="div"
                className="error"
                style={{ display: "none", color: "red" }}
                render
              />
            </Div5>
            <Div6>
              <label htmlFor="contact">Contact Number:</label>
              <Field
                type="number"
                name="contact"
                label="Contact Number :"
                placeholder="10 digit mobile number"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              />
              <ErrorMessage
                name="contact"
                component="div"
                className="error"
                style={{ color: "red" }}
              />
            </Div6>
            <Div7>
              <label htmlFor="email">Valid Email:</label>
              <Field
                type="email"
                name="email"
                placeholder="Email"
                style={{
                  border: "1px solid black",
                  padding: "5px",
                  opacity: 0.5,
                  borderRadius: "4px"
                }}
              />
              <ErrorMessage name="email" component="div" />
            </Div7>

            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                color: "#00ff00",
                padding: "5px",
                opacity: 0.5,
                borderRadius: "4px"
              }}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </RegistrationFormContainer>
  );
};

export default Registration;

const HeadingP = styled.span`
  font-size: 20px;
  color: #000;
  text-decoration: underline;
`;

const RegistrationFormContainer = styled.div`
  Form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-stat;
    justify-content: flex-start;
    flex-direction: column;
    padding: 3%;
    label {
      margin-right: 20px;
    }

    input {
      height: 30px;
      width: 200px;
      padding: 3px 10px;
    }

    select {
      height: 30px;
      width: 200px;
      padding: 3px 10px;
    }
    p {
      font-size: 20px;
      color: red;
    }
  }
`;
const Div1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div4 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div5 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div6 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div7 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div8 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
const Div9 = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-stat;
  justify-content: flex-start;
  margin: 10px 0;
`;
