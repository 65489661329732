export const MenulistTesting = [
  {
    title: "Home",
    url: "/"
  },
  {
    title: "About Us",
    url: "/legacy",
    dropdownItems: [
      { title: "Legacy", url: "/legacy" },
      { title: "Chairman's Desk", url: "/chairman-desk" },
      { title: "Principal Desk", url: "/principal-desk" },
      { title: "Leadership & Faculty", url: "/leadership" }
    ]
  },
  {
    title: "Academic",
    url: "/academic"
  },
  {
    title: "Life @ GDGPS",
    url: "/lifeatgdgps"
  },
  {
    title: "Admission",
    url: "/admission"
  },
  {
    title: "Career",
    url: "/career"
  },
  {
    title: "Blog",
    url: "/blogs"
  },
  {
    title: "Contact Us",
    url: "/contact"
  },
  {
    title: "Login",
    url: "/login"
  }
];
