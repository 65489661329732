import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Preparatory = () => {
  return (
    <div>
      <Navbaartesting />
      Preparatory
      <Footer />
    </div>
  );
};

export default Preparatory;
