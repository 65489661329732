import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Academic from "./pages/Academic";
import Lifeatgdgps from "./pages/Lifeatgdgps";
import AdmissionEnquary from "./pages/AdmissionEnquary";
import Career from "./pages/Career";
import Pagenotfound from "./pages/Pagenotfound";
import Login from "./pages/Login";
import Blog from "./pages/Blog";
import Contactus from "./pages/Contactus";
import FAQ from "../src/pages/admission/FAQ";
import AdmissionProcessPolicies from "./pages/admission/AdmissionProcessPolicies";
import OnlineModal from "./pages/admission/OnlineModal";
import Registration from "./pages/admission/Registration";
import DocumentsRequired from "./pages/admission/DocumentsRequired";
import FeeStructure from "./pages/admission/FeeStructure";
import SuccessStories from "./pages/admission/SuccessStories";
import CampusTour from "./pages/admission/CampusTour";
import SchoolProspectus from "./pages/admission/SchoolProspectus";
import Legacy from "./pages/about/Legacy";
import Chairman from "./pages/about/Chairman";
import Leadership from "./pages/about/Leadership";
import Principal from "./pages/about/Principal";
import Newslatter from "./pages/about/Newslatter";
import Collaboration from "./pages/about/Collaboration";

import WalktheCampus from "./pages/WalktheCampus";
import Legitmacy from "./pages/about/Legitmacy";
import Synergy from "./pages/about/Synergy";
import Proficiency from "./pages/about/Proficiency";
import Prospect from "./pages/about/Prospect";
import Foundational from "./events/Foundational";
import Preparatory from "./events/Preparatory";
import Middle from "./events/Middle";
import Secondary from "./events/Secondary";
import VideoGallery from "./events/VideoGallery";
import ImgGallery from "./events/ImgGallery";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="" element={""}></Route>
          <Route path="/legacy" element={<Legacy />} />
          <Route path="/chairman-desk" element={<Chairman />} />
          <Route path="/principal-desk" element={<Principal />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/newsletter" element={<Newslatter />} />
          <Route path="/collaboration" element={<Collaboration />} />
          <Route path="/walk-the-campus" element={<WalktheCampus />} />

          <Route path="/academic" element={<Academic />} />
          <Route path="/lifeatgdgps" element={<Lifeatgdgps />} />
          <Route path="/admission" element={<AdmissionEnquary />}>
            <Route path="/admission/faq" element={<FAQ />} />
            <Route
              path="/admission/admission-process"
              element={<AdmissionProcessPolicies />}
            />
            <Route path="/admission/online-modal" element={<OnlineModal />} />
            <Route path="/admission/registration" element={<Registration />} />
            <Route
              path="/admission/documents-required"
              element={<DocumentsRequired />}
            />
            <Route path="/admission/fee-structure" element={<FeeStructure />} />
            <Route
              path="/admission/success-stories"
              element={<SuccessStories />}
            />
            <Route path="/admission/campus-tour" element={<CampusTour />} />
            <Route
              path="/admission/school-prospectus"
              element={<SchoolProspectus />}
            />
          </Route>
          <Route path="/career" element={<Career />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/legitimacy" element={<Legitmacy />} />
          <Route path="/synergy" element={<Synergy />} />
          <Route path="/proficiency" element={<Proficiency />} />
          <Route path="/prospect" element={<Prospect />} />
          <Route path="/foundational" element={<Foundational />} />
          <Route path="/preparatory" element={<Preparatory />} />
          <Route path="/middle" element={<Middle />} />
          <Route path="/secondary" element={<Secondary />} />
          <Route path="/vid-gallery" element={<VideoGallery />} />
          <Route path="/img-gallery" element={<ImgGallery />} />

          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
