import React from "react";
import styled from "styled-components";
import { LuPhoneCall } from "react-icons/lu";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
import FooterBg from "../school_images/slide-4.jpg";

const Footer = () => {
  return (
    <FooterSection>
      <Div1>
        <h1>G D Goenka Public School | Darbhanga |</h1>
        <p>
          SH 50, Darbhanga Samastipur Road, Poiriya, Bishanpur, Darbhanga, Bihar
          846002
        </p>
      </Div1>
      <Div2>
        <h1> Follow Us On </h1>
        <span className="flex justify-between">
          <LuPhoneCall className=" m-1  " />{" "}
          <Link to="tel:+91 6235000007">+91 6235000007</Link>
        </span>
        <span className="flex justify-between">
          <LuPhoneCall className=" m-1  " />{" "}
          <Link to="tel:+91 9153973287">+91 9153973287</Link>
        </span>
        <span className="flex justify-between">
          <LuPhoneCall className=" m-1  " />{" "}
          <Link to="tel:+91 9153966833">+91 9153966833</Link>
        </span>
        <span className="flex justify-between">
          <LuPhoneCall className=" m-1  " />{" "}
          <Link to="mailto: info@gdgoenkadarbhanga.com">
            info@gdgoenkadarbhanga.com
          </Link>
        </span>
      </Div2>
      <Div3>
        <h1>Lets Go Social !</h1>
        <div className="flex ">
          <Link
            to="https://www.facebook.com/gdgoenkadarbhanga/"
            target="_blank"
          >
            <FaFacebook />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
            target="_blank"
          >
            <IoLogoYoutube />
          </Link>
          <Link
            to="https://www.instagram.com/gdgoenkadarbhanga"
            target="_blank"
          >
            <FaInstagramSquare />
          </Link>
          <Link
            to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
            target="_blank"
          >
            <IoLogoLinkedin />
          </Link>
        </div>
      </Div3>
      <span></span>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5% 7%;
  flex-wrap: wrap;
  border: 1px solid black;
  width: 100vw;
  background-color: #2c975b;
  color: #fff;
  background-image: url(${FooterBg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`;
const Div1 = styled.div`
  width: 30%;
  min-width: 350px;
  margin-top: 2rem;
  color: brown;
  display: flex;
  color: brown;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  p {
    color: white;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;
const Div2 = styled.div`
  margin-top: 2rem;
  width: 30%;
  min-width: 350px;
  display: flex;
  color: brown;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    &:hover {
      transition: all 0.5s ease-in-out;
      color: brown;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;
const Div3 = styled.div`
  width: 30%;
  color: brown;
  min-width: 350px;
  margin-top: 2rem;
  display: flex;
  color: brown;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  h1 {
    padding-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    font-size: 20px;
    font-size: 2rem;
    padding: 1rem 0.5rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    &:hover {
      transition: all 0.5s ease-in-out;
      color: brown;
    }
  }
`;
