import React from "react";
import styled from "styled-components";

const EnqButton = () => {
  return (
    <div>
      <EqButton>Enquiry Now</EqButton>
    </div>
  );
};

export default EnqButton;

const EqButton = styled.button`
 border: none;
  color: white;
  padding: 7px 20px;
  background-color: #2c975b;
  border-radius: 2px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  position: absolute;
  top:46%;
  right: 0;
   display: none;
  &:hover {
    background-color: brown;
`;
