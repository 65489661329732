import React from "react";

import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const Blog = () => {
  return (
    <div>
      <Navbaartesting />
      Blog
      <Footer />
    </div>
  );
};

export default Blog;
