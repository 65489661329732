import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import Footer from "../../components/Footer";
import PrincipalImg from "../../school_images/event_1.jpg";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";

const Principal = () => {
  return (
    <div>
      <Navbaartesting />
      <MainImageDiv>
        <img src={PrincipalImg} alt="principal_image" className="w-full" />
        <SocialMediaIconsDiv>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.facebook.com/gdgoenkadarbhanga/"
              target="_blank"
            >
              <FaFacebook />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
              target="_blank"
            >
              <IoLogoYoutube />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.instagram.com/gdgoenkadarbhanga"
              target="_blank"
            >
              <FaInstagramSquare />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
            <Link
              to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
              target="_blank"
            >
              <IoLogoLinkedin />
            </Link>
          </div>
        </SocialMediaIconsDiv>
        <MainHeading className=" font-bold pt-10 px-10 uppercase  absolute ">
          Principal's Desk
        </MainHeading>
      </MainImageDiv>
      <PrincipalContainer>
        <h1>FROM THE PRINCIPAL’S DESK</h1>
        <p>Dear Parents,</p>
        <p>
          We welcome you to G.D. Goenka Public School, Sector 48, with a
          commitment to nurture and equip young learners with the uncommon
          ability of self exploration and development. Our aim is to nurture the
          children to become confident, sensitive, socially committed and well
          rounded individuals, equipped to become young envoys of progress. Our
          children grow in the best of environment and under the guidance of
          competent faculty, elements indispensable today for gaining knowledge
          and understanding.
        </p>
        <p>
          One of the largest campus developments in the city of Gurugram, GDGPS
          is addressing the contemporary needs of the learners by successfully
          creating a dynamic environment to trigger learning and to facilitate
          the teaching process. The visual aid being provided by Interactive
          Smart Boards make the teaching learning process interesting for the
          children. State of the art language lab with digital setup is an
          effective tool to polish the verbal and linguistic abilities of the
          children. For the avid readers, the library is a place to unwind and
          delve into the fascinating world created by the writers. A full time
          Space and Astronomy Club, along with other facilities, is providing
          the children with a platform to learn and grow in their area of
          interest.
        </p>
        <p>
          The school follows a structured Co Scholastic curriculum which is
          implemented through extensive facilities for visual and performing
          arts. A complete block, with multiple studios, has been created for
          these activities. Structured sports program is an encouragement for
          students to pursue sports seriously. The extensive sports set-up
          includes three world class synthetic Lawn Tennis Courts, Senior and
          Junior Swimming Pools, Basketball and Badminton courts, Skating Rink,
          Yoga and Aerobics studios, Athletics, Football, Cricket, Youngsters
          Play Area and Swings.
        </p>
        <p>
          We ensure the complete holistic development of every child. Holistic
          education is based on the premise that each person finds identity,
          meaning, and purpose in life through connections to the community, to
          the natural world, and to spiritual values such as compassion and
          peace. By encouraging reflection and questioning rather than passive
          memorization of "facts," teachers in such a set up keep alive the
          "flame of intelligence" that is so much more than abstract
          problem-solving skill. A holistic teacher develops the learner into a
          positive, confident, critical and independent learner who is able to
          create value in life.
        </p>
        <p>
          GD Goenka Public School, at Sector 48, Gurugram carries the vision to
          provide education with a holistic approach. There is a whole new world
          here that awaits the children - the world of art, the world of music,
          the world of dance, the world of sports, the world of technology, the
          world of creativity, the world of engineering, the world of
          management; to give wings to learners' dreams and aspirations to fly
          to the world of their choice.
        </p>
        <span>ABBBB</span>
        <p>Principal</p>
      </PrincipalContainer>
      <Footer />
    </div>
  );
};

export default Principal;
const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;
const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
`;

const PrincipalContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;
