import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const ImgGallery = () => {
  return (
    <div>
      <Navbaartesting />
      Image Gallery
      <Footer />
    </div>
  );
};

export default ImgGallery;
