import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoImage from "../school_images/gdgoenkalogo.png";
import "../styleCSS/tenetsStyle.css";
import NoticeEvents from "./NoticeEvents";
import Footer from "../components/Footer";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import tenet1_image from "../school_images/connectivity_image_for_School.jpg";
import tenet2_image from "../school_images/tenet_2.jpg";
import tenet3_image from "../school_images/tenet3_image.jpg";
import tenet4_image from "../school_images/connectivity_image_for_School.jpg";
import { FaAnglesRight } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

const HeroTenetsSection = () => {
  return (
    <HeroTenetsDiv>
      <BelowHeroDiv>
        <QuickLinkDiv>
          <Link to="/legacy">Legacy</Link>
          <Link to="/chairman-desk">Chairman's Address</Link>
          <Link to="/principal-desk">Principal's Desk</Link>
          <Link to="/leadership">Leadership & Faculty</Link>
          <Link to="/newsletter">Newsletters</Link>
          <Link to="/collaboration">Collaboration</Link>
          <Link to="/walk-the-campus">Walk The Campus</Link>
          {/* <img src={LogoImage} alt="logo_img" /> */}
        </QuickLinkDiv>
        <FourTenetsHead>
          <h1>The Four Tenets</h1>
          <p>
            With roots of LEGITIMACY growing into stems of SYNERGY, flowers of
            PROFICIENCY bloom into fruits of PROSPECTS.
          </p>
        </FourTenetsHead>
      </BelowHeroDiv>
      <FourTenets>
        <div className="tenet tenet1">
          <img src={tenet1_image} alt="tenet1" />
          <h3>legitimacy</h3>
          <Link to="/legitimacy"> Read More...</Link>
        </div>
        <div className="tenet tenet2">
          <img src={tenet2_image} alt="tenet1" />
          <h3>Synergy</h3>
          <Link to="/synergy"> Read More...</Link>
        </div>
        <div className="tenet tenet3">
          <img src={tenet3_image} alt="tenet1" />
          <h3>PROFICIENCY</h3>
          <Link to="/proficiency"> Read More...</Link>
        </div>
        <div className="tenet tenet4">
          <img src={tenet4_image} alt="tenet1" />
          <h3>prospects</h3>
          <Link to="/prospect"> Read More...</Link>
        </div>
      </FourTenets>
      <NoticeEvents />
      <Footer />
    </HeroTenetsDiv>
  );
};

export default HeroTenetsSection;

const HeroTenetsDiv = styled.section`
  z-index: 999999899999;
`;
const BelowHeroDiv = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const QuickLinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: red;
  min-width: 30%;
  margin-left: 8%;
  background-image: { LogoImage  };
  padding-top: 2.5rem;
  margin-top: -3rem;
  z-index: 1;
 
  
  a {
    color: #fff;
    margin-left: 3rem;
    text-decoration: none;
    font-size: 2vw;
    margin-bottom: 1rem;
    padding:  .4rem 0;
    @media (max-width: 668px) {
    font-size: 20px;
  }
  }
  a:hover {
    color: black;
  }
  @media (max-width: 668px) {
    width: 80%;
    margin-top: 0;
  }
`;

const FourTenetsHead = styled.div`
  font-family: "Roboto Slab", serif;
  padding-left: 2rem;
  padding-right: 2rem;
  h1 {
    font-weight: bold;
    font-size: 4vw;
    margin-top: 6vh;
    margin-bottom: 3vh;
    @media (max-width: 668px) {
      font-size: 32px;
    }
  }
  p {
    font-size: 3vw;
    @media (max-width: 668px) {
      font-size: 20px;
    }
  }
`;
const FourTenets = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 3% 7%;
  padding: 2%;
  background: #ddf5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
