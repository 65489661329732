import React, { useState, useEffect } from "react";
import "../styleCSS/Marquee.css"; // Import your CSS file
import { Link } from "react-router-dom";

const Marque = () => {
  const [isHovered, setIsHovered] = useState(false);

  // Data for your marquee elements
  const marqueeData = [
    { text: "Admission 2024-25", link: "/admission" },
    { text: "Media Coverage", link: "/media" },
    { text: "Events Calendar", link: "/events" },
    {
      text: "School aims at nurturing students' intellect.",
      link: "/admission"
    },
    {
      text: "Goenkan Gazette",
      link: "/media"
    }
  ];

  // Configure animation duration based on the number of elements
  const animationDuration = marqueeData.length * 2; // Adjust as needed

  // Toggle hover state
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    // Pause animation on hover
    const marquee = document.getElementById("marquee");
    if (marquee) {
      isHovered
        ? (marquee.style.animationPlayState = "paused")
        : (marquee.style.animationPlayState = "running");
    }
  }, [isHovered]);

  return (
    <div className={`marquee-container ${isHovered ? "paused" : ""}`}>
      <div
        id="marquee"
        className="marquee"
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        style={{ animationDuration: `${animationDuration}s` }}
      >
        {marqueeData.map((element, index) => (
          <div key={index} className="marquee-item">
            <Link to={element.link}>{element.text}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marque;
