import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "aos/dist/aos.css";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import Slider1 from "../school_images/slide-0.png";
import Slider2 from "../school_images/slide-1.png";
import Slider3 from "../school_images/slide-2.png";
import Slider4 from "../school_images/slide-3.jpg";
import Slider5 from "../school_images/slide-4.jpg";
import Slider6 from "../school_images/slide-6 1.png";
import Slider7 from "../school_images/slide-4 1.png";
import Slider8 from "../school_images/slide-5 1.png";
import Slider9 from "../school_images/slide-5.jpg";

import { Link } from "react-router-dom";

const Slider = () => {
  // Create state variables for the index and image array
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const images = [
    Slider1,
    Slider2,
    Slider3,
    Slider4,
    Slider5,
    Slider6,
    Slider7,
    Slider8,
    Slider9
  ];

  const content2 = [
    "Best Urologist in the North Bihar",
    "Over 14000 Operation Done Successfully",
    "Over 70 OPD Daily",
    "Most Awarded Doctor in North Bihar",
    "Ex-Urologist at IGIMS, Patna"
  ];

  // Use useEffect to increment the index and update the image every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIndex1((prevIndex) => (prevIndex + 1) % content2.length);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  // Render the current image
  return (
    <CarouselContainer>
      <ImageDiv>
        <LeftButton
          onClick={() =>
            setIndex((prevIndex) => (prevIndex - 1) % images.length)
          }
        >
          <BsChevronLeft />
        </LeftButton>
        <SlidingImg
          src={images[index]}
          alt="Slider Image G D Goenka Public School, Darbhanga"
        />
        <RightButton
          onClick={() =>
            setIndex((prevIndex) => (prevIndex + 1) % images.length)
          }
        >
          <BsChevronRight />
        </RightButton>
      </ImageDiv>
      <OverText>
        Higher <br /> Stronger <br /> Brighter
      </OverText>
      <SocialMediaIconsDiv>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.facebook.com/gdgoenkadarbhanga/"
            target="_blank"
          >
            <FaFacebook />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
            target="_blank"
          >
            <IoLogoYoutube />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.instagram.com/gdgoenkadarbhanga"
            target="_blank"
          >
            <FaInstagramSquare />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
          <Link
            to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
            target="_blank"
          >
            <IoLogoLinkedin />
          </Link>
        </div>
      </SocialMediaIconsDiv>
    </CarouselContainer>
  );
};

export default Slider;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;

const OverText = styled.div`
  position: absolute;
  top: 10%;
  left: 15%;
  font-size: 100px;
  color: #fff;
  font-family: cursive;
  @media screen and (max-width: 640px) {
    font-size: 50px;
  }
`;

const CarouselContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contents: center;

  position: relative;
  background-color: #2c975b;
`;

const ImageDiv = styled.div`
  height: 80vh;
  width: 100vw;

  @media screen and (min-width: 200px) and (max-width: 600px) {
    width: 100vw;
    height: auto;
    min-height: 330px;
  }
`;
const SlidingImg = styled.img`
  height: 80vh;
  width: 100vw;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    width: 100vw;

    min-height: 330px;
    height: 100%;
  }
`;

const HyperLink = styled(Link)`
  text-decoration: none;
  color: #050572;
  font-weight: 500;
  background: #ddd;
  color: red;
  margin-top: 1em;
  padding: 10px 30px;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 30px;
  border: none;
  &:hover {
    background: red;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px) {
    font-size: 1rem;
    font-weight: 500;
  }
  @media screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

const WelcomeText = styled.p`
  margin-bottom: 0;
  background: #fff;
  border-radius: 25px;
  padding: 10px 20px;
  color: blue;
  font-size: 2rem;
  font-weight: 600;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    font-size: 1rem;
    font-weight: 500;
  }
  @media screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
const BookNowButtom = styled.button`
  background: #ddd;
  color: red;
  margin-top: 1em;
  padding: 10px 30px;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 30px;
  border: none;
  &:hover {
    background: red;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px) {
    font-size: 1rem;
    font-weight: 500;
  }
  @media screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

const MaskLayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  opacity: 0.8;
  height: 100px;
  position: absolute;
  height: 80vh;
  width: 100vw;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    width: 100vw;
    max-height: 258.75px;
    height: 100%;
  }
`;

const LeftButton = styled.button`
  z-index: 11;
  position: absolute;
  top: 50%;
  left: 2%;
  padding: 5px 10px;
  font-size: 25px;
  border: 1px solid #c81313;
  opacity: 0.4;
  &:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px) {
    top: 60%;
    left: 1%;
  }
`;

const RightButton = styled.button`
  position: absolute;
  z-index: 11;
  top: 50%;
  right: 2%;
  padding: 5px 10px;
  font-size: 25px;
  border: 1px solid #c80505;
  opacity: 0.4;
  &:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px) {
    top: 60%;
    right: 1%;
  }
`;

const Imgdiv = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    width: 95%;
    height: auto;
  }
`;

const Submitbutton = styled.button`
  align-self: center;
  margin-top: 1.5rem;
  font-size: 20px;
  font-weight: 500;
  color: #c81313;
  border: 1px solid #c81313;
  padding: 5px 15px 7px 15px;
  border-radius: 7px;
  text-decoration: none;
  background-color: white;
  &:hover {
    background-color: #c81313;
    color: white;
    transition: all 0.5s ease-in-out;
  }
`;

const BookingImg = styled.img``;

const BookigContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    flex-direction: column;
  }
`;

const BookAppointment = styled.div`
  border-radius: 10px;
  width: 50vw;
  height: auto;
  background-color: rgb(18, 150, 156);
  padding: 1rem;
  margin-top: 2rem;
  @media screen and (min-width: 200px) and (max-width: 600px) {
    width: 90vw;
    margin-top: 0rem;
  }
`;
