import React from "react";
import Navbaartesting from "../components/Navbaartesting";
import Footer from "../components/Footer";

const VideoGallery = () => {
  return (
    <div>
      <Navbaartesting />
      VideoGallery
      <Footer />
    </div>
  );
};

export default VideoGallery;
