import React from "react";
import styled from "styled-components";
import Logo from "../school_images/school_logo.jpeg";
import { MenulistTesting } from "./MenulistTesting";
import "../styleCSS/Navbar.css";
import { useState, createContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { BiMenuAltRight } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import Four_Square from "../school_images/dropdown_logo.png";

const NavigationDiv = styled.div`
  z-index: 99;
  position: sticky;
  top: 0;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
  background-color: #fff;
  height: 60px;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: #000;

  &:hover {
    color: red;
    dip
  }

  a {
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    
    align-items: flex-start;
  }
`;

const MenuItem = styled.li`
  position: relative;

  &:hover ${DropdownContent} {
    display: block;
  }
`;

const Navbaartesting = () => {
  const [clicked, setClicked] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuLists = MenulistTesting.map(
    ({ url, title, dropdownItems }, index) => {
      const isHovered = hoveredItem === index;

      return (
        <MenuItem
          key={index}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
          className={title === "Admission" ? "admission-menu" : ""}
        >
          <NavLink activeClassName="active" to={url}>
            {title}
          </NavLink>
          {dropdownItems && isHovered && (
            <DropdownContent>
              {dropdownItems.map((item, idx) => (
                <Link key={idx} to={item.url}>
                  {item.title}
                </Link>
              ))}
            </DropdownContent>
          )}
        </MenuItem>
      );
    }
  );

  const handleClicked = () => {
    setClicked(!clicked);
  };

  return (
    <nav>
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="menu-icon" onClick={handleClicked}>
        {clicked ? (
          <FaTimes />
        ) : (
          <img src={Four_Square} alt="Four_square" height="30" width="30" />
        )}
      </div>
      <ul
        className={clicked ? "menu-list" : "menu-list close"}
        onClick={handleClicked}
      >
        {menuLists}
      </ul>
      <EnquiryFormButton
        to="https://gdgdarbhanga.gdgoenka.com/mvc/std/DynamicEnquiryForm?id=gm44E2x75eawnpF5K7VGUQ&istrue=true"
        target="_blank"
      >
        Enquiry Form
      </EnquiryFormButton>
    </nav>
  );
};

export default Navbaartesting;

const EnquiryFormButton = styled(Link)`
  border: none;
  color: white;
  padding: 7px 20px;
  background-color: #2c975b;
  border-radius: 2px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    background-color: brown;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
