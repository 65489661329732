import React from "react";
import Footer from "../../components/Footer";
import Navbaartesting from "../../components/Navbaartesting";
import LegitimacyImg from "../../school_images/slide-5 1.png";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";

const Legitmacy = () => {
  return (
    <div>
      <Navbaartesting />

      <MainImageDiv>
        <img src={LegitimacyImg} alt="Legitimacy" className="w-full" />
      </MainImageDiv>
      <SocialMediaIconsDiv>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.facebook.com/gdgoenkadarbhanga/"
            target="_blank"
          >
            <FaFacebook />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
            target="_blank"
          >
            <IoLogoYoutube />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
          <Link
            to="https://www.instagram.com/gdgoenkadarbhanga"
            target="_blank"
          >
            <FaInstagramSquare />
          </Link>
        </div>
        <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
          <Link
            to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
            target="_blank"
          >
            <IoLogoLinkedin />
          </Link>
        </div>
      </SocialMediaIconsDiv>

      <LegitimacyContainer>
        <h1>Legitimacy</h1>
        <p>
          A child entering school life at the age of three is most authentic in
          its ways - genuine, honest and transparent. The real challenge is to
          maintain this purity all through school life and beyond.
        </p>
        <p>
          GDGPS Sector 48 focuses on retaining the uniqueness of every child,
          without them getting into conflict with others. This is achieved by
          keeping Authentic Learning at the centre of the teaching-learning
          process.
        </p>
        <h2>HOW IS THIS ACHIEVED? </h2>
        <p>
          Through learning that is personally meaningful for the learner; one
          that relates to the real-world outside of school, provides an
          opportunity to think in the modes of a particular discipline and where
          the means of assessment reflect the learning process.
        </p>
        <ImageTextDiv>
          <ImageDiv>
            <img src="" alt="image" className="w-[49%] " />
          </ImageDiv>
          <TextDiv>
            <p>
              It is believed that any activity becomes authentic only when a
              learner owns it personally. Only when a learner thinks of an
              activity as ‘real or genuine’, does it become authentic.
              Indigenously developed school programs are the means by which
              GDGPS Sector 48 ensures that students find learning authentic and
              stimulating. Students are engaged in the kind of things that they
              would do in ‘real life’, outside of the environment where learning
              is taking place.
            </p>
            <p>
              By learning to think, Goenkans are encouraged to develop the art
              of learning different disciplines like History, Mathematics,
              Science etc using the same tools as Professional Scholars would.
            </p>
            <p>
              Moreover, having assessments that are embedded into learning; that
              is, structuring assessment in a manner that students learn
              something while taking the test, GDGPS Sector 48 ensures
              Authenticity in Education.
            </p>
            <p>
              Most importantly, by helping students discover their TRUE SELF, we
              instill in them the confidence to stand by their belief system.
            </p>
          </TextDiv>
        </ImageTextDiv>
      </LegitimacyContainer>
      <Footer />
    </div>
  );
};

export default Legitmacy;

const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
`;

const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;

const LegitimacyContainer = styled.div`
  padding: 5% 7%;
  text-align: left;
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 50px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const ImageTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

const ImageDiv = styled.div`
  width: 50%;
  height: auto;
  min-height: 300px;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const TextDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
  p {
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;
