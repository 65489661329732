import React from "react";
import Navbaartesting from "../../components/Navbaartesting";
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../components/Footer";
import CampusTourImg from "../../school_images/hostel.jpeg";

const CampusTour = () => {
  return (
    <>
      <Navbaartesting />
      <MainImageDiv>
        <img src={CampusTourImg} alt="Career_image" className="w-full" />
        <SocialMediaIconsDiv>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.facebook.com/gdgoenkadarbhanga/"
              target="_blank"
            >
              <FaFacebook />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.youtube.com/channel/UCyG9n-Bbb75mEgQ5FWLtggg"
              target="_blank"
            >
              <IoLogoYoutube />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3">
            <Link
              to="https://www.instagram.com/gdgoenkadarbhanga"
              target="_blank"
            >
              <FaInstagramSquare />
            </Link>
          </div>
          <div className=" w-[60px] h-[60px] border-2 rounded-full flex items-center justify-center mb-3 ">
            <Link
              to="https://www.linkedin.com/in/gd-goenka-darbhanga-b88430217"
              target="_blank"
            >
              <IoLogoLinkedin />
            </Link>
          </div>
        </SocialMediaIconsDiv>
      </MainImageDiv>
      <Footer />
    </>
  );
};

export default CampusTour;

const MainHeading = styled.span`
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #2c975b;
  font-size: 50px;
`;

const MainImageDiv = styled.div`
  padding: 0 0%;
  max-height: 500px;
  position: relative;
  img {
    max-height: 500px;
    height: 100%;
    width: 100%;
  }
`;

const SocialMediaIconsDiv = styled.span`
  color: #fff;
  position: absolute;
  top: 20%;
  right: 2%;
  a {
    color: #fff;
    font-size: 30px;
    &:hover {
      color: brown;
    }
  }
  div {
    @media (max-width: 640px) {
      font-size: 25px;
      width: 45px;
      height: 45px;
    }
  }
`;
