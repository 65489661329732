import React from "react";
import HeadVideo from "../school_images/Basic_English_Class_Facebook_Cover.mp4";
import HeadImage from "../school_images/hostel.jpeg";
import Navbaartesting from "../components/Navbaartesting";
import styled from "styled-components";
import "../styleCSS/indexpage.css";
import HeroTenetsSection from "./HeroTenetsSection";
import "../styleCSS/Navbar.css";
import EnqButton from "../components/EnqButton";
import Carousal from "../components/Carousal";
import Slider from "../components/Slider";

const Home = () => {
  return (
    <HomeContainer>
      <Navbaartesting />
      {/* <Carousal /> */}
      <Slider />
      {/*
      <video id="video1" autoplay muted loop>
        <source
          src="https://www.youtube.com/watch?v=dgZ8QT26leE&ab_channel=GDGoenkaPublicSchool-Darbhanga"
          type="video/mp4"
          autoplay
          muted
          loop
        />
      </video>
       <img src={HeadImage} alt="background_image" /> */}
      <HeroTenetsSection />
      <EnqButton />
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  width: 100vw;
  height: auto;
`;
